var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm.$options.components['profile-need-help'] ? [_c('profile-need-help')] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "justify-content-center h-100 mt-4"
  }, [_c('div', {
    staticClass: "chat"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header msg_head"
  }, [_c('div', {
    staticClass: "d-flex bd-highlight"
  }, [_c('div', {
    staticClass: "user_info"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Chandran")])])])]), _c('div', {
    staticClass: "card-body msg_card_body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start mb-4"
  }, [_c('div', {
    staticClass: "msg_cotainer"
  }, [_vm._v(" Lorem Ipsum is dummy text of the printing "), _c('span', {
    staticClass: "msg_time"
  }, [_vm._v("8:40 AM")])])]), _c('div', {
    staticClass: "d-flex justify-content-end mb-4"
  }, [_c('div', {
    staticClass: "msg_cotainer_send"
  }, [_vm._v(" Lorem Ipsum is dummy text of the printing "), _c('span', {
    staticClass: "msg_time_send"
  }, [_vm._v("8:55 AM")])])]), _c('div', {
    staticClass: "d-flex justify-content-start mb-4"
  }, [_c('div', {
    staticClass: "msg_cotainer"
  }, [_vm._v(" Lorem Ipsum is dummy text of the printing "), _c('span', {
    staticClass: "msg_time"
  }, [_vm._v("9:00 AM")])])]), _c('div', {
    staticClass: "d-flex justify-content-end mb-4"
  }, [_c('div', {
    staticClass: "msg_cotainer_send"
  }, [_vm._v(" Lorem Ipsum is dummy text of the printing "), _c('span', {
    staticClass: "msg_time_send"
  }, [_vm._v("9:05 AM")])])]), _c('div', {
    staticClass: "d-flex justify-content-start mb-4"
  }, [_c('div', {
    staticClass: "msg_cotainer"
  }, [_vm._v(" Lorem Ipsum is dummy text of the printing Lorem Ipsum is dummy text of the printing Lorem Ipsum is dummy text of the printing "), _c('span', {
    staticClass: "msg_time"
  }, [_vm._v("9:07 AM")])])]), _c('div', {
    staticClass: "d-flex justify-content-end mb-4"
  }, [_c('div', {
    staticClass: "msg_cotainer_send"
  }, [_vm._v(" Lorem Ipsum is dummy text of the printing Lorem Ipsum is dummy text of the printing Lorem Ipsum is dummy text of the printing "), _c('span', {
    staticClass: "msg_time_send"
  }, [_vm._v("9:10 AM")])])]), _c('div', {
    staticClass: "d-flex justify-content-start mb-4"
  }, [_c('div', {
    staticClass: "msg_cotainer"
  }, [_vm._v(" Lorem Ipsum is dummy text of the printing "), _c('span', {
    staticClass: "msg_time"
  }, [_vm._v("9:12 AM")])])])]), _c('div', {
    staticClass: "card-footer"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('textarea', {
    staticClass: "form-control type_msg",
    attrs: {
      "name": "",
      "placeholder": "Write your message..."
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('span', {
    staticClass: "input-group-text send_btn"
  }, [_c('b-icon', {
    attrs: {
      "icon": "cursor-fill",
      "aria-label": "Help"
    }
  })], 1)])])])])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }