<template>
    <b-container>
        <b-row>
            <b-col md="3">
                <template v-if="$options.components['profile-need-help']">
                    <profile-need-help />
                </template>
            </b-col>
            <b-col md="9">
                <div class="justify-content-center h-100 mt-4">
                    <div class="chat">
                        <div class="card">
                            <div class="card-header msg_head">
                                <div class="d-flex bd-highlight">
                                    <div class="user_info">
                                        <h5 class="mb-1">Chandran</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body msg_card_body">
                                <div class="d-flex justify-content-start mb-4">
                                    <div class="msg_cotainer">
                                        Lorem Ipsum is dummy text of the printing
                                        <span class="msg_time">8:40 AM</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end mb-4">
                                    <div class="msg_cotainer_send">
                                        Lorem Ipsum is dummy text of the printing
                                        <span class="msg_time_send">8:55 AM</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-start mb-4">
                                    <div class="msg_cotainer">
                                        Lorem Ipsum is dummy text of the printing
                                        <span class="msg_time">9:00 AM</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end mb-4">
                                    <div class="msg_cotainer_send">
                                        Lorem Ipsum is dummy text of the printing
                                        <span class="msg_time_send">9:05 AM</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-start mb-4">
                                    <div class="msg_cotainer">
                                        Lorem Ipsum is dummy text of the printing Lorem Ipsum is dummy
                                        text of the printing Lorem Ipsum is dummy text of the printing
                                        <span class="msg_time">9:07 AM</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end mb-4">
                                    <div class="msg_cotainer_send">
                                        Lorem Ipsum is dummy text of the printing Lorem Ipsum is dummy
                                        text of the printing Lorem Ipsum is dummy text of the printing
                                        <span class="msg_time_send">9:10 AM</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-start mb-4">
                                    <div class="msg_cotainer">
                                        Lorem Ipsum is dummy text of the printing
                                        <span class="msg_time">9:12 AM</span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="input-group">
                                    <textarea name="" class="form-control type_msg"
                                        placeholder="Write your message..."></textarea>
                                    <div class="input-group-append">
                                        <span class="input-group-text send_btn">
                                            <b-icon icon="cursor-fill" aria-label="Help"></b-icon>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "chat-with-seller",
    components: {},
    data() {
        return {};
    },
    async mounted() { },
    methods: {},
};
</script>

<style scoped>
.chat {
    margin-top: auto;
    margin-bottom: auto;
}

.card {
    height: 540px;
    border-radius: 15px !important;
    background: #f8f8f8;
}

.contacts_body {
    padding: 0.75rem 0 !important;
    overflow-y: auto;
    white-space: nowrap;
}

.msg_card_body {
    overflow-y: auto;
}

.card-header {
    border-radius: 15px 15px 0 0 !important;
    border-bottom: 0 !important;
}

.card-footer {
    border-radius: 0 0 15px 15px !important;
    border-top: 0 !important;
    padding: 0;
}

.type_msg {
    background-color: #f0f0f0 !important;
    border: 1px solid #d8d8d8 !important;
    height: 44px !important;
    overflow-y: auto;
}

.type_msg:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.send_btn {
    background-color: #ec4e0f !important;
    border: 0 !important;
    color: white !important;
    cursor: pointer;
}

.contacts {
    list-style: none;
    padding: 0;
    margin: 0;
}

.contacts li {
    width: 100% !important;
    padding: 5px 10px;
    background: #f8f8f8;
    border-bottom: 1px solid #f0f0f0;
}

.user_img {
    height: 70px;
    width: 70px;
    border: 1.5px solid #f5f6fa;
}

.img_cont_msg {
    height: 40px;
    width: 40px;
}

.user_info {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
}

.user_info p {
    font-size: 13px;
}

.user_info h5 {
    font-size: 1rem;
}

.msg_cotainer {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    background-color: #ffdfc3;
    padding: 10px;
    position: relative;
    max-width: 360px;
    font-size: 14px;
}

.msg_cotainer_send {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    background-color: #f0f0f0;
    padding: 10px;
    position: relative;
    max-width: 360px;
    font-size: 14px;
}

.msg_time {
    position: absolute;
    left: 0;
    bottom: -17px;
    color: #606060;
    font-size: 10px;
}

.msg_time_send {
    position: absolute;
    right: 0;
    bottom: -15px;
    color: #606060;
    font-size: 10px;
}

.msg_head {
    position: relative;
}

@media (max-width: 576px) {
    .contacts_card {
        margin-bottom: 15px !important;
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
}
</style>
